/* global config */

angular.module(config.app.name).service('$_procedures', function ($_http) {
    const url = config.apiURL + 'procedures/';
    return {
        createProcedure: (data) => {
            let _url = url + 'new';
            return new Promise((resolve, reject) => {
                $_http.post(_url, data).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        get: (by, page = 1, pageSize) => {
            let _url = url, params = [];
            return new Promise((resolve, reject) => {
                if (by) {
                    if (by.id) {
                        _url += by.id;
                    } else { // options only for many results
                        for (let i in by) {
                            if (['id', 'type'].indexOf(i) < 0) {
                                params.push('filter[' + i + ']=' + by[i]);
                            }
                        }
                        if (page > 1) {
                            params.push('page=' + parseInt(page));
                        }
                        if (pageSize) {
                            params.push('pageSize=' + parseInt(pageSize));
                        }
                    }
                }
                if (params.length > 0) {
                    _url += '?' + params.join('&');
                }
                $_http.get(_url).then((res) => {
                    if (typeof res.data.count !== 'undefined' &&
                            typeof res.data.rows !== 'undefined') {
                        resolve({
                            count: res.data.count,
                            items: res.data.rows
                        });
                    } else {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        updateProcedure(data = null, procedureId = null, procedureType = null, step = null) {
            if (data === null || procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/' + (procedureType ? procedureType + (step ? '/' + step : '') : '');
            return new Promise((resolve, reject) => {
                $_http.put(_url, data).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getData(_type = null, filters = null, showError = true) {
            if (_type === null) {
                return Promise.reject();
            }
            let _url = url + 'data/' + _type + '' + (filters !== null ? '?' + filters : '');
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getWebAppPrepaidAccount() {
            let _url = config.apiURL + 'webapps/prepaid-account';
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getProcedureAmounts(procedureId = null) {
            if (procedureId === null) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/price';
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getProcedurePaymentLink(procedureId = null) {
            if (procedureId === null) {
                return Promise.reject();
            }
            let _url = url + procedureId + '/payment';
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        sendPaymentLink(procedureId = null, phoneNumber = null) {
            if (procedureId === null) {
                return Promise.reject();
            }
            let method = 'get', data = null;
            let _url = url + procedureId + '/payment/notify';
            if (typeof phoneNumber === 'string') {
                method = 'put';
                data = {
                    phone: phoneNumber
                };
            }
            return new Promise((resolve, reject) => {
                $_http[method](_url, data).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        createPaymentRequest(procedureId = null) {
            let _url = url + procedureId + '/create-payment-request';
            return new Promise((resolve, reject) => {
                $_http.put(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        /**
         * Get procedure documents.
         * @param {string} procedureId Procedure Id
         * @param {string} procedureType Procedure type
         * @param {Object} options
         * @param {boolean} options.toSign Get documents to sign
         * @param {boolean} options.receipt Get procedure receipt
         * @param {boolean} options.forBuyer Get documents for seller only* 
         * @param {boolean} options.forSeller Get documents for seller only
         * @returns {Promise}
         */
        getDocuments(procedureId = null, procedureType = null, options = {}) {
            if (procedureId === null || procedureType === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/' +
                    (options.receipt === true ? 'receipt' :
                            ((options.toSign ? 'sign/' : 'documents/') + procedureType) +
                            (options.forBuyer ? '/?filter[personType]=buyer' : (options.forSeller ? '/?seller=1' : '')));
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        _getDocuments(procedureId = null, procedureType = null, forBuyerOnly = false) {
            let _url = url + procedureId + '/documents/' + procedureType + (forBuyerOnly ? '/?filter[personType]=buyer' : '');
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getPaid(procedureId = null) {
            let _url = url + procedureId + '/paid';
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getSigned(procedureId = null) {
            let _url = url + procedureId + '/signed';
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        sendSignatureLink(procedureId = null, procedureType = null, phoneNumber = null) {
            if (procedureId === null || procedureType === null || phoneNumber === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/sign/' + procedureType + '/notify';
            return new Promise((resolve, reject) => {
                $_http.put(_url, {
                    phone: phoneNumber
                }).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        uploadDocument(procedureId = null, document = null, documentType = null) {
            if (procedureId === null || document === null || documentType === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/upload/' + documentType;
            return new Promise((resolve, reject) => {
                $_http.put(_url, document, {
                    headers: {'Content-Type': undefined}
                }).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        getDocumentsUploadLink(procedureId = null, phoneNumber = null) {
            if (procedureId === null) {
                return Promise.reject();
            }
            let method = 'get', data = null;
            let _url = url + procedureId + '/documents/upload-link';
            if (typeof phoneNumber === 'string') {
                _url += '/notify';
                method = 'put';
                data = {
                    phone: phoneNumber
                };
            }
            return new Promise((resolve, reject) => {
                $_http[method](_url, data).then((res) => {
                    resolve(res.data);
                }, (err) => {
                    reject(err);
                });
            });
        },
        getChatMessages: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/chat';
            return new Promise((resolve, reject) => {
                $_http.get(_url).then((res) => {
                    if (res.data) {
                        resolve(res.data);
                    }
                }, (err) => {
                    reject(err);
                });
            });
        },
        postChatMessage: (procedureId = null, data) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/chat/post';
            return new Promise((resolve, reject) => {
                $_http.put(_url, data).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        },
        setChatMessagesAsRead: (procedureId = null) => {
            if (procedureId === null) {
                return Promise.reject('NoData');
            }
            let _url = url + procedureId + '/chat/read';
            return new Promise((resolve, reject) => {
                $_http.put(_url).then(() => {
                    resolve();
                }, (err) => {
                    reject(err);
                });
            });
        }
    };
});